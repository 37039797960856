/* cyrillic-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 400;
	src: url(1.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 400;
	src: url(2.woff2) format("woff2");
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 400;
	src: url(3.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 400;
	src: url(4.woff2) format("woff2");
	unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 400;
	src: url(5.woff2) format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 400;
	src: url(6.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 700;
	src: url(7.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 700;
	src: url(8.woff2) format("woff2");
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 700;
	src: url(9.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 700;
	src: url(10.woff2) format("woff2");
	unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 700;
	src: url(11.woff2) format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: italic;
	font-weight: 700;
	src: url(12.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 400;
	src: url(13.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 400;
	src: url(14.woff2) format("woff2");
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 400;
	src: url(15.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 400;
	src: url(16.woff2) format("woff2");
	unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 400;
	src: url(17.woff2) format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 400;
	src: url(18.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 700;
	src: url(19.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 700;
	src: url(20.woff2) format("woff2");
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 700;
	src: url(21.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 700;
	src: url(22.woff2) format("woff2");
	unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 700;
	src: url(23.woff2) format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Ubuntu Mono";
	font-style: normal;
	font-weight: 700;
	src: url(24.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
